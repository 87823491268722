@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

html {
  height: 100%;
}

body {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  overflow: hidden;
  height: 100%;

}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f7;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f7;
}

::-webkit-scrollbar-thumb {
  background: #4A397F;
  border-radius: 4px;
}

:root {
  --bg-color: #3C83F5;
  --color: #3c83F5;
}

#root {
  height: 100%;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}