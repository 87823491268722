.prescription_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  background-color: #fff;
  border-radius: 12px;

  .buttons_container {
    display: flex;
    justify-content: space-between;
  }

  .filters_container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .SearchInput {
      display: flex;
      width: 300px !important;
      height: 37px;
      z-index: 0;
    }

    .DatePicker {
      width: 20%;
    }

    .DropDown {
      button {
        height: 37px;
        color: #6f7f9a;
        border: 1px solid #e5e5ea;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .prescription_table_container {
    height: 100%;
    max-height: 100vh;
  }
  .pagination_buttons {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    p {
      margin: 0 10px;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  .rs-checkbox-wrapper .rs-checkbox-inner:before {
    border: 1px solid #6f7f9a;
  }
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    height: 30px;
  }

  ul {
    width: 10em;
  }

  button {
    height: 20px;
  }
  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.modal_body {
  display: flex;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  overflow-x: hidden;
  .rs-btn-primary {
    margin-top: 20px;
  }

  .input_itens {
    position: absolute;
    background-color: #f1f2f6;
    width: 50%;
    border-radius: 0 0 6px 6px;
    z-index: 3;

    .input_field_item {
      width: 100%;
      padding: 1em;
      cursor: pointer;
    }
  }

  .resident_search_input_field {
    width: 100%;
    align-self: center;
    margin: 0;
    padding: 0;
  }
  .date_fields {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;

    div {
      z-index: 0;
    }

    .date_field_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    div {
      width: 90%;
      .rs-btn-default {
        width: 100%;
      }
    }
  }
  .search_input {
    display: flex;
    margin-top: 30px;
    width: 100%;
    div {
      width: 40%;
    }
  }
  .prescription_table {
    width: 100%;
    height: 100px;
    .recurrence_container {
      width: 100%;
      cursor: pointer;

      .recurrent_buttons_container {
        margin-top: 20px;
      }

      .tag_input_field_container {
        border: 1px solid #e5e5ea;
        height: 50px;

        .rti--input {
          width: 100%;
        }
      }
      .drop_buttons {
        width: 91%;
        display: flex;
        justify-content: space-evenly;
        align-self: center;
        margin: 5%;
      }

      div {
        width: 100%;
        .rs-dropdown-menu {
          width: 500px;
        }
      }
    }
  }
  .comments_input_field {
    width: 100%;
    margin-top: 30px;
  }
}
.prescription_details {
  .rs-input {
    z-index: 0;
  }
}
.rs-dropdown {
  background-color: transparent;
}

.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0A305A;
  border-color: #0A305A;
}
.btn-outline-primary {
  color: #0A305A;
  border-color: #0A305A;

  &:hover {
    background-color: #0A305A;
    border-color: #0A305A;
  }
}

@media screen and (max-width: "414px") {
  .prescription_container {
    overflow-y: scroll;
  }
  .prescription_container .buttons_container {
    display: flex;
    justify-content: center;
  }
  .filters_container {
    flex-direction: column;
  }
  .prescription_container .filters_container .SearchInput {
    width: 100%;
    height: 37px;
    z-index: 0;
  }
  .date_fields {
    display: flex;
  }
  .prescription_container .filters_container .DatePicker {
    width: 100%;
    margin: 0.3em 0;
  }
  .rs-modal-content {
    margin-top: 6em;
    margin-left: 1em;
    width: 90%;
  }
  .modal_body .search_input div {
    width: 100%;
  }
}

.flex-position {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
