.container__sidebar {
  min-height: 100%;
  background-color: #ffffff;
  font-family: "Nunito" sans-serif;
  .group-itens {
    width: 237px;
    position: fixed;
    background-color: #ffffff;
    // width: 20em;
    padding-top: 2em;
    // width: 13%;
    height: -webkit-fill-available;
    padding: 1em 0.4em;
    // border-right: 1px solid rgba(0, 0, 0, 0.11);
  }
}

.rs-sidenav-body {
  overflow-y: auto !important;
  overflow-x: none !important;

  .rs-dropdown-item {
    width: 270px !important;
  }
}

.titleside {
  // background-color: khaki;
  // height: 3em;
  display: flex;
  align-items: center;
  padding-left: 1.4em;
  margin-top: 2em;

  img {
    width: 100px;
    margin-right: 0.5em;
  }
  h1 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0em;
    color: #ffffff;
  }
}

.menu {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
}
.item__menu {
  display: flex;
  font-size: 14px;
  padding: 10px 10px;
  align-items: center;

  &:hover {
    background-color: #888888;
    color: #fff;
    border-radius: 10px;
    // border: 0.1px solid rgba(128, 128, 128, 0.082);
  }
  p {
    margin-bottom: 0;
  }
}
.item__menu_active {
  display: flex;
  font-size: 14px;
  padding: 1em 1em;
  align-items: center;
  width: 100%;
  // background-color: #373d52;
  // box-shadow: 1px 1px 1px 1px #4cabd4 inset;
  background-color: #0A305A;
  color: #fff;
  border-radius: 10px;
  border: 0.1px solid rgba(128, 128, 128, 0.082);

  p {
    margin-bottom: 0;
  }
}

.icon__menu {
  padding-right: 1em;
}

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  font-weight: 400;
  background-color: white;
  color: #1b2559;
  transition: none;

  .rs-dropdown-toggle-icon {
    margin-right: 15px;
  }

  &:hover {
    color: white;
    background-color: rgba(128, 128, 128, 0.945);
  }
}
.rs-dropdown-menu {
  width: 100%;
  a {
    text-decoration: none;
  }
}

#linkmenu {
  text-decoration: none;
  color: #1b2559;
  font-weight: 400;
  padding: 0.6em 0em;
}

#dropItem {
  &:hover {
    background-color: #00afa917;
    color: black;
  }
  &:active {
    background-color: #00afa917;
    color: black;
  }
}

.rs-dropdown .rs-dropdown-menu {
  width: max-content;
  padding: 0.5em;
  overflow-y: auto;
}
@media screen and (max-height: 768px) {
  .titleside {
    padding: 0;
    margin: 0;
    h1 {
      font-size: 20px;
    }
  }
  .item__menu {
    font-size: 13px;
  }
}

@media screen and (max-width: 1600px) {
  .item__menu {
    // font-size: 12px;
  }
  .item__menu_active {
    font-size: 12px;
    padding: 1em 1em;
  }
}

@media screen and (max-width: "414px") {
  .container__sidebar {
    display: none;
  }
}
