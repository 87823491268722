.container-full-page {
  display: grid;
  grid-template-rows: 0.1fr 1fr;
  height: 123vh;
  padding: 0px 5px;
  margin: 2px 10px;

  @media screen and (max-width: 816px) {
    height: 173vh !important;
  }

  .container-description {
    h2 {
      font-weight: 700;
      color: #0A305A;
    }

    p {
      font-weight: 600;
      color: rgba(128, 128, 128, 0.945);
    }
  }

  .container-full-body {
    width: 100%;
    height: 107vh;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .container-full-body {
    height: 102vh !important;
  }
}

@media screen and (min-width: 1758px) {
}
