.container__geral {
	display: grid;

	grid-template-columns: 1fr;
	// grid-template-rows: 0.16fr 3fr;
	background-color: #f1f2f6;
	/* grid-gap: 0.2rem; */

	p {
		margin-bottom: 0;
	}
	.div_navbar {
		width: 100%;
	}
	.container__side-and-view {
		display: flex;
	}
}

.div__side {
	height: 100%;
	width: 13%;
}
.view__page {
	width: 87%;
	margin: 1em 1em;
	background-color: #f1f2f6;
	height: 100vh;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

.pages_routers {
	margin: 2em;
	border-radius: 20px;
	padding: 2em;
	z-index: 100;
	background: #fff;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
	border-color: #0A305A !important;
	/* border-color: var(--rs-checkbox-checked-bg); */
	background-color: #0A305A !important;
	/* background-color: var(--rs-checkbox-checked-bg); */
}

#root,
#root > div {
	overflow: hidden;
}

@media screen and (max-width: 1600px) {
	h1 {
		font-size: 30px;
	}
}
@media screen and (max-width: '414px') {
	.view__page {
		width: 100vw;
		padding: 0.4em;
		margin: 5em 0;
		overflow-y: scroll;
	}
	.div__side {
		width: 0%;
	}
}

.rs-table-cell-content {
	padding: 5px 10px !important;
}

.css-1lkylyi {
	margin-top: 5px !important;
	margin-left: 16px;
	margin-right: 16px;
}

.title-information {
	font-weight: 730;
	color: #0A305A;
	font-size: x-large;
}
